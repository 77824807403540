import React from 'react';
import aboutimage from "../assets/images/hero.png";

function About() {
  return (
    <section className="bg-gray-900 text-white py-16 px-4">
      <div className="container mx-auto">
        {/* About Me Section */}
        <h2 className="text-4xl font-bold text-center mb-8 text-yellow-400">About Me</h2>
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Updated About Image with Hover Effect */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src={aboutimage}
              alt="Bibek Kumar Yadav"
              className="rounded-full object-cover w-80 h-80 md:w-96 md:h-96 mx-auto border-8 border-yellow-500 transition-transform duration-300 hover:scale-110 hover:rotate-3 hover:shadow-yellow-500 hover:shadow-2xl"
            />
          </div>

          <div className="md:w-1/2 md:pl-8">
            <h3 className="text-3xl font-semibold mb-4 text-yellow-400">Hello! I'm Bibek</h3>
            <p className="text-lg mb-4">
              I am a Full Stack Developer and co-founder of Wisdom Technologies. My expertise spans both front-end and back-end development, with a focus on scalable, high-performance applications.
            </p>
            <p className="text-lg mb-4">
              Proficient in MERN stack, Django, and mobile app development using Flutter, I aim to create user-friendly, responsive web solutions tailored to client needs.
            </p>
            <p className="text-lg mb-4">
              My skills also extend to graphic design, helping me merge functionality with aesthetics. I’m always keen to explore new tech and contribute to open-source projects.
            </p>
          </div>
        </div>

        {/* Skills Section */}
        <div className="mt-12">
          <h3 className="text-3xl font-semibold mb-4 text-yellow-400">Skills</h3>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>🔹 Full Stack Development (MERN, Django)</li>
            <li>🔹 Mobile App Development (Android, iOS)</li>
            <li>🔹 Graphic Design</li>
            <li>🔹 Python Programming</li>
            <li>🔹 Team Leadership</li>
          </ul>
        </div>

        {/* What I Do Section */}
        <div className="mt-16">
          <h3 className="text-3xl font-semibold text-center mb-8 text-yellow-400">What I Do</h3>
          <p className="text-center text-lg mb-12">
            With over 3 years of experience, I build software for clients worldwide. Below is an overview of my main technical skills.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Skill Cards with Hover Effect */}
            {[
              {icon: "fab fa-js-square", color: "text-yellow-500", title: "Vanilla JavaScript", description: "Expert in ES6+, DOM manipulation, and building interactive components."},
              {icon: "fab fa-react", color: "text-blue-500", title: "React & Vue", description: "Building dynamic UIs and SPAs using modern frameworks."},
              {icon: "fab fa-node", color: "text-green-500", title: "Node.js", description: "Scalable back-end services and APIs development."},
              {icon: "fab fa-python", color: "text-blue-500", title: "Python & Django", description: "Building high-performance systems and APIs."},
              {icon: "fab fa-css3-alt", color: "text-orange-500", title: "HTML & CSS", description: "Responsive design, CSS animations, and HTML5 semantics."},
              {icon: "fab fa-sass", color: "text-pink-500", title: "Sass & LESS", description: "Maintaining scalable and structured CSS using pre-processors."},
            ].map((skill, idx) => (
              <div key={idx} className="bg-white text-gray-900 p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <div className="flex items-center space-x-4 mb-4">
                  <i className={`${skill.icon} text-4xl ${skill.color}`}></i>
                  <h4 className="text-xl font-semibold">{skill.title}</h4>
                </div>
                <p>{skill.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Why Choose Me Section */}
        <div className="mt-16">
          <h3 className="text-3xl font-semibold text-center mb-8 text-yellow-400">Why Choose Me?</h3>
          <div className="space-y-6 text-lg">
            {[
              { title: "💻 Full-Stack Expertise", description: "Proficient in front-end and back-end technologies, delivering complete solutions." },
              { title: "📱 Mobile First", description: "Ensuring mobile-optimized designs for a smooth user experience." },
              { title: "⏱ Timely Delivery", description: "Committed to meeting deadlines and delivering projects on time." },
              { title: "📈 Scalable Solutions", description: "Building with scalability in mind, allowing your business to grow seamlessly." },
              { title: "👥 Client-Centric Approach", description: "Tailoring solutions to your specific needs and involving you throughout the process." },
            ].map((reason, idx) => (
              <div key={idx} className="bg-white text-black p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <h4 className="text-xl font-bold mb-2">{reason.title}</h4>
                <p>{reason.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
