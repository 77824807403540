import React from 'react';
import image from "../assets/images/hero.png";
import additionalImage from "../assets/images/mob.png";
import cv from "../assets/docs/CV bibek.pdf";

function Hero() {
  return (
    <section className="bg-gray-900 min-h-screen flex flex-col items-center justify-center text-center text-white px-4 py-8 md:py-12">
      <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-8 mt-8">
        
        {/* Profile Image */}
        <div className="relative w-32 h-32 sm:w-40 sm:h-40 md:w-60 md:h-60 lg:w-80 lg:h-80">
          <img
            src={image}
            className="rounded-full w-full h-full border-8 border-yellow-500 transition-transform duration-300 hover:scale-105"
            alt="Profile"
            loading="lazy"
          />
          <p className="font-bold text-center text-sm md:text-base mt-2">Co-founder of Wisdom Technologies</p>
        </div>

        {/* Profile Description */}
        <div className="text-center md:text-left max-w-lg">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mt-12 md:mt-0">
            Bibek Kumar Yadav
          </h1>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl mt-4 sm:mt-4 max-w-xl mx-auto md:mx-0">
            I specialize in building scalable, high-performance applications using the MERN stack and Django. With a strong background in both front-end and back-end development, I am dedicated to creating user-friendly and responsive web solutions.
          </p>

          {/* Call-to-Action Buttons */}
          <div className="mt-6 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center md:justify-start">
            <a
              href={cv}
              download
              className="bg-yellow-500 hover:bg-yellow-600 text-black font-semibold px-6 py-3 rounded-full transition duration-300"
            >
              Download CV
            </a>
            <a
              href="mailto:bibekyadav274@gmail.com?subject=Hiring%20Inquiry&body=Hi%20Bibek,"
              className="bg-transparent border-2 border-yellow-500 text-white hover:bg-yellow-500 px-6 py-3 rounded-full transition duration-300"
            >
              Hire Me Now
            </a>
          </div>
        </div>

        {/* Right Side Image */}
        <div className="w-full md:w-60 lg:w-80 -mt-8 md:-mt-10 lg:-mt-12"> {/* Adjusted negative margin here */}
          <img
            src={additionalImage}
            alt="Right Side"
            className="w-full h-full rounded-lg md:hidden transition-transform duration-300 hover:scale-105" // Added hover effect
            loading="lazy"
          />
          <img
            src={additionalImage}
            alt="Right Side"
            className="w-full h-full rounded-lg hidden md:block transition-transform duration-300 hover:scale-105" // Added hover effect
            loading="lazy"
          />
        </div>
      </div>

      {/* Social Media Links */}
      <div className="mt-12 flex justify-center space-x-6">
        <a 
          href="https://www.linkedin.com/in/bibek-kumar-yadav-a684b4285/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-300 hover:text-yellow-500 transition-transform transform hover:scale-110"
        >
          <i className="fab fa-linkedin-in text-xl md:text-2xl"></i>
        </a>
        <a 
          href="https://www.instagram.com/bibek.yadav.923/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-300 hover:text-yellow-500 transition-transform transform hover:scale-110"
        >
          <i className="fab fa-instagram text-xl md:text-2xl"></i>
        </a>
        <a 
          href="https://github.com/BibekYadav151" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-300 hover:text-yellow-500 transition-transform transform hover:scale-110"
        >
          <i className="fab fa-github text-xl md:text-2xl"></i>
        </a>
      </div>
    </section>
  );
}

export default Hero;
