import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  // Close menu when a link is clicked on mobile
  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="bg-gray-900 p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo as a Link to Hero Section */}
        <Link to="/" className="text-yellow-500 text-2xl font-bold">
          B. K. Y.
        </Link>

        {/* Full Menu for larger screens */}
        <ul className="hidden md:flex space-x-6">
          <li>
            <Link to="/" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              About
            </Link>
          </li>
          <li>
            <Link to="/services" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/Blog" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="/contact" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Contact
            </Link>
          </li>
        </ul>

        {/* Hamburger Menu Icon for smaller screens */}
        <div className="md:hidden">
          <button 
            aria-label="Toggle menu"
            onClick={() => setMenuOpen(!menuOpen)} 
            className="text-gray-300 focus:outline-none">
            <i className={`fas ${menuOpen ? 'fa-times' : 'fa-bars'} text-2xl`}></i>
          </button>
        </div>
      </div>

      {/* Dropdown Menu for smaller screens */}
      {menuOpen && (
        <ul className="md:hidden flex flex-col items-center space-y-4 mt-4">
          <li>
            <Link to="/" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              About
            </Link>
          </li>
          <li>
            <Link to="/services" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/portfolio" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Portfolio
            </Link>
          </li>
          <li>
            <Link to="/contact" className="text-gray-300 hover:text-yellow-500 transition duration-300" onClick={handleLinkClick}>
              Contact
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
