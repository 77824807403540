import React, { useState } from 'react';
import axios from 'axios';

function ContactForm() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Sending form data to the backend
      await axios.post('/api/contact', formData);
      setStatusMessage('Message sent successfully!');
    } catch (error) {
      setStatusMessage('Failed to send message.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
      {/* Name Input */}
      <div>
        <label htmlFor="name" className="block mb-2 text-lg">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="border p-3 w-full mb-4 transition-all duration-300 bg-gray-100 hover:bg-white hover:border-blue-500 focus:bg-white focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200"
        />
      </div>

      {/* Email Input */}
      <div>
        <label htmlFor="email" className="block mb-2 text-lg">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="border p-3 w-full mb-4 transition-all duration-300 bg-gray-100 hover:bg-white hover:border-blue-500 focus:bg-white focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200"
        />
      </div>

      {/* Message Textarea */}
      <div>
        <label htmlFor="message" className="block mb-2 text-lg">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className="border p-3 w-full mb-4 transition-all duration-300 bg-gray-100 hover:bg-white hover:border-blue-500 focus:bg-white focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200"
        />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="bg-blue-500 text-white px-6 py-3 w-full transition-transform duration-300 hover:scale-105 hover:bg-blue-600"
      >
        Send
      </button>

      {/* Status Message */}
      <p className="mt-4 text-center text-green-600">{statusMessage}</p>
    </form>
  );
}

export default ContactForm;
