import React from 'react';

const services = [
  {
    title: 'Custom Software Development',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>Custom Software Development:</strong> Tailored software for your business.</li>
        <li><strong>Enterprise Solutions:</strong> High-quality enterprise software.</li>
        <li><strong>Cloud Applications:</strong> Scalable cloud-based solutions.</li>
        <li><strong>API Development:</strong> Secure APIs for seamless integrations.</li>
      </ul>
    ),
  },
  {
    title: 'Full Stack Web Development',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>Front-End Development:</strong> Stunning interfaces with React, Angular.</li>
        <li><strong>Back-End Development:</strong> Robust servers using Node.js, Django.</li>
        <li><strong>Database Management:</strong> Expertise in MySQL, MongoDB, and PostgreSQL.</li>
      </ul>
    ),
  },
  {
    title: 'Mobile App Development',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>Native App Development:</strong> Swift for iOS, Kotlin for Android.</li>
        <li><strong>Cross-Platform Development:</strong> Flutter, React Native.</li>
      </ul>
    ),
  },
  {
    title: 'Front-End Design and Development',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>Responsive Web Design:</strong> Mobile-first, adaptable layouts.</li>
        <li><strong>UI/UX Design:</strong> User-centered, engaging designs.</li>
      </ul>
    ),
  },
  {
    title: 'API & Third-Party Integration',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>Third-Party API Integration:</strong> Payment gateways, social APIs, etc.</li>
        <li><strong>Custom API Development:</strong> Secure, custom API solutions.</li>
      </ul>
    ),
  },
  {
    title: 'E-commerce Development',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>Custom E-commerce Platforms:</strong> Tailored for your business needs.</li>
        <li><strong>Payment Gateway Integration:</strong> Stripe, PayPal, and more.</li>
      </ul>
    ),
  },
  {
    title: 'Digital Marketing Solutions',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>SEO Optimization:</strong> On-page and off-page SEO services.</li>
        <li><strong>Google Ads & PPC:</strong> Tailored paid campaigns for growth.</li>
      </ul>
    ),
  },
  {
    title: 'App & Website Maintenance',
    description: (
      <ul className="list-disc pl-6 mt-4">
        <li><strong>Security Updates:</strong> Regular updates and security patches.</li>
        <li><strong>Bug Fixes:</strong> Swift resolution of issues and bugs.</li>
      </ul>
    ),
  },
];

function ServicesPage() {
  return (
    <section className="bg-gradient-to-b from-[#1c1c1c] to-[#111] text-white py-16">
      <div className="container mx-auto px-8">
        <h2 className="text-5xl font-extrabold text-center mb-12 text-yellow-500 tracking-wide">
          Services I Offer
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
          {services.map((service, index) => (
            <div 
              key={index} 
              className="group bg-gray-900 relative overflow-hidden hover:bg-[#ffaf40] hover:text-black transform transition-all duration-500 p-8 rounded-2xl shadow-2xl"
            >
              {/* Decorative Elements */}
              <div className="absolute -top-16 -right-16 h-40 w-40 rounded-full bg-yellow-500 opacity-40 group-hover:scale-125 transform transition-transform duration-700"></div>
              <div className="absolute -bottom-16 -left-16 h-40 w-40 rounded-full bg-yellow-700 opacity-30 group-hover:scale-125 transform transition-transform duration-700"></div>
              
              {/* Title */}
              <h3 className="text-3xl font-bold mb-6 group-hover:text-4xl transform transition-all duration-500">
                {service.title}
              </h3>
              
              {/* Description */}
              <div className="text-gray-400 group-hover:text-black transition-colors duration-500">
                {service.description}
              </div>

              {/* Hover Circle Animation */}
              <div className="absolute inset-0 rounded-full bg-yellow-500 opacity-0 group-hover:opacity-20 transform scale-0 group-hover:scale-125 transition-transform duration-500 ease-in-out"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesPage;
