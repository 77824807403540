import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white text-center p-4">
      <p>© 2024 Bibek - All Rights Reserved</p>
      <div className="flex justify-center">
        <a 
          href="https://github.com/BibekYadav151" 
          className="mx-2 hover:text-yellow-500 transition duration-300"
          target="_blank" 
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        <a 
          href="https://www.linkedin.com/in/bibek-kumar-yadav-a684b4285/" 
          className="mx-2 hover:text-yellow-500 transition duration-300"
          target="_blank" 
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </div>
    </footer>
  );
}

export default Footer;
