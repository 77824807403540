import React from 'react';
import ContactForm from '../components/ContactForm'; // Assuming ContactForm is in the components folder
import qrCodeImage from '../assets/images/yadav.png'; // Import the QR code image (adjust the path as needed)

function ContactPage() {
  return (
    <section className="container mx-auto p-8">
      <h2 className="text-4xl font-bold text-center mb-12">Get In Touch</h2>

      <div className="flex flex-col md:flex-row justify-between items-start">
        {/* QR Code Section - Left */}
        <div className="mr-6 md:w-1/3">
          <div className="bg-white shadow-md rounded-lg p-6 text-center transition-transform duration-300 hover:shadow-xl hover:scale-105">
            <h3 className="text-2xl font-semibold mb-6">Scan to contact</h3>
            <img src={qrCodeImage} alt="QR Code" className="w-60 h-60 mx-auto" />
          </div>
        </div>

        {/* Contact Form Section - Right */}
        <div className="bg-white shadow-md rounded-lg p-6 md:p-10 w-full md:w-2/3">
          <ContactForm />
        </div>
      </div>

      {/* Divider */}
      <div className="border-t border-gray-300 my-8"></div>

      {/* Redesigned Wisdom Technologies Info */}
      <div className="mt-12 flex justify-center items-center">
        <div className="bg-gray-100 rounded-lg shadow-lg max-w-lg p-8 text-center hover:bg-yellow-500 hover:shadow-2xl transition-all duration-300">
          <h3 className="text-3xl font-bold text-gray-800 mb-4">Wisdom Technologies</h3>

          {/* Location */}
          <div className="text-lg text-gray-600 mb-2 hover:text-white transition-colors duration-300">
            <i className="fas fa-map-marker-alt mr-2"></i> New Baneshwor, Kathmandu
          </div>

          {/* Contact */}
          <div className="text-lg text-gray-600 mb-4 hover:text-white transition-colors duration-300">
            <i className="fas fa-phone-alt mr-2"></i> 9805987922
          </div>

          {/* Website */}
          <a 
            href="https://www.wisdompvt.com/" 
            className="text-yellow-600 text-lg font-semibold hover:text-white transition-transform duration-300 transform hover:scale-105"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <i className="fas fa-globe mr-2"></i> www.wisdompvt.com
          </a>
        </div>
      </div>
    </section>
  );
}

export default ContactPage;
